import { onMounted, onUnmounted, ref } from '@vue/composition-api'
import stickybits from 'stickybits'

export default function useSticky (selector, defaultOptions = {}) {
  const instance = ref(null)

  function create (options = {}) {
    setTimeout(() => {
      const element = document.querySelector(selector)
      if (element) {
        instance.value = stickybits(selector, {
          ...defaultOptions,
          ...options
        })
      }
    })
  }

  onMounted(() => {
    create()
  })

  onUnmounted(() => {
    if (instance.value && document.querySelector(selector)) {
      instance.value.cleanup()
    }
  })

  return {
    create
  }
}
