<template>
  <div
    :class="{
      'offer-header--danger': inDanger
    }"
    class="offer-header tw-relative tw-flex tw-items-center tw-justify-between tw-bg-gray-200 tw-px-9 tw-py-2 tw-rounded-tl tw-rounded-tr"
  >
    <!-- Proposal content -->
    <template
      v-if="isProposalView && proposal && (proposal.status === 'accepted' || !isOfferAvailableForBooking)"
    >
      <!-- Accepted -->
      <div
        v-if="proposal.status === 'accepted'"
        class="tw-flex tw-justify-between tw-items-center tw-flex-1"
        data-test="accepted-proposal"
      >
        <h1
          class="tw-text-xl tw-font-medium tw-mb-0 tw-text-blue-500"
          v-text="$tc('proposal_list.proposals.accepted', 1)"
          data-test="title"
        />

        <ui-button
          :to="{
            name: 'Mission',
            params: {
              uuid: proposal.mission && proposal.mission.uuid
            }
          }"
          class="tw-rounded-full tw-border-none tw-mr-3 tw-flex-grow-0 tw-text-white"
          variant="primary"
          data-test="view-mission"
        >
          {{ $t('show_offer_detail') | capitalize }}
        </ui-button>
      </div>

      <!-- Unavailable for booking -->
      <div
        v-else-if="!isOfferAvailableForBooking"
        class="tw-flex tw-justify-between tw-items-center tw-flex-1"
        data-test="unavailable-offer"
      >
        <h1
          class="tw-text-xl tw-font-medium tw-mb-0 tw-text-white"
          v-text="$t('offers.titles.offer_unavailable')"
          data-test="title"
        />
      </div>
    </template>

    <template
      v-else
    >
      <ui-button
        v-if="canHideOffer"
        v-b-tooltip.hover
        :title="$t('hide_offer') | capitalize"
        :disabled="!isOfferAvailableForBooking"
        class="offer-header__hide tw-mr-3 tw-flex-grow-0"
        variant="light"
        fab
        data-test="hide-offer"
        @click="askHide"
      >
        <div
          :class="{
            'tw-text-blue-500' : isOfferAvailableForBooking
          }"
          class="tw-flex tw-items-center"
        >
          <ui-ctk-icon
            class="tw-text-3xl tw-block"
            name="hide"
            data-test="icon"
          />
        </div>
      </ui-button>

      <div class="tw-flex-1">
        <h1
          class="tw-text-xl tw-font-medium tw-mb-0 tw-text-blue-500"
          v-text="$t('offer_with_ref', {
            ref: reference
          })"
          data-test="reference"
        />
        <div
          class="tw-text-gray-700"
          v-text="$t('app.values.expires_at', {
            date: $moment(offer.expires_at).format('LLL')
          })"
          data-test="expiration"
        />
      </div>
    </template>

    <ui-button
      v-b-tooltip.hover
      :title="$t('close') | capitalize"
      class="offer-header__close tw-top-3 tw-right-3 tw-flex-grow-0"
      variant="light"
      fab
      data-test="close-offer"
      @click="closeOffer"
    >
      <div
        class="tw-flex tw-items-center"
      >
        <ui-ctk-icon
          class="tw-text-3xl tw-block"
          name="close"
          data-test="icon"
        />
      </div>
    </ui-button>
  </div>
</template>

<script>
  import { defineComponent, computed, toRefs } from '@vue/composition-api'

  import { EventBus } from '@/services/EventBus'
  import useRoute from '@/composables/useRoute'
  import useStore from '@/composables/useStore'
  import useRouter from '@/composables/useRouter'

  /**
   * @module component - OfferHeader
   */
  export default defineComponent({
    props: {
      offer: {
        type: Object,
        required: true
      },
      proposal: {
        type: Object,
        default: null
      }
    },
    setup (props) {
      const route = useRoute()
      const router = useRouter()
      const store = useStore()

      const { offer, proposal } = toRefs(props)

      const isProposalView = computed(() => {
        return ['Proposals', 'ProposalStates'].includes(route.value.name)
      })

      /**
       * Returns true or false if the user can hide the offer or not
       * @function canHideOffer
       */
      const canHideOffer = computed(() => {
        if (isProposalView.value) return false
        if (offer.value && offer.value.proposal) return false

        return true
      })

      const reference = computed(() => offer.value && offer.value.reference)
      const isOfferAvailableForBooking = computed(() => {
        return offer.value && (typeof offer.value.available_for_booking === 'undefined' || offer.value.available_for_booking)
      })

      const inDanger = computed(() => {
        if (!isProposalView.value) return false
        if (proposal.value && proposal.value.status === 'accepted') return false

        return !isOfferAvailableForBooking.value
      })

      const askHide = () => {
        EventBus.$emit('confirm-hide-modal')
      }

      function closeOffer () {
        /**
         * Since this component is used on both offers list and offer map detail, we need
         * to have different behaviours when closing the offer.
         */
        if (route.value.name === 'Offer') {
          router.value.push({
            name: 'Offers'
          })
            .catch(() => { /* noop */ })
            .finally(() => {
              store.value.dispatch('offers/resetCurrentOffer')
            })
        } else {
          store.value.dispatch('offers/resetCurrentOffer')
          store.value.dispatch('offers/resetCurrentProposal')
        }
      }

      return {
        canHideOffer,
        reference,
        isOfferAvailableForBooking,
        isProposalView,
        inDanger,

        askHide,
        closeOffer
      }
    }
  })
</script>

<style lang="scss" scoped>
.offer-header {
  min-height: 64px;
}
.offer-header--danger {
  --tw-bg-opacity: 1;
  background-color: rgba(215, 40, 63, var(--tw-bg-opacity));
}
.offer-header--danger .offer-header__close {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.offer-header--danger .offer-header__close:hover {
  background-color: transparent;
}
.offer-header__close {
  position: absolute;
}
.offer-header__close:not(:hover), .offer-header__hide:not(:hover) {
  background-color: transparent;
}
</style>
