<template>
  <div class="offer-map-section tw-relative">
    <l-map
      ref="map"
      :zoom="mapData.zoom"
      :max-zoom="mapData.maxZoom"
      :min-zoom="mapData.minZoom"
      :options="{
        ...mapData.options,
        scrollWheelZoom: false
      }"
      :center="mapData.center"
      class="leaflet-map"
    >
      <l-tile-layer
        :url="mapData.url"
        :attribution="mapData.attribution"
      />

      <l-control-zoom
        :position="mapData.controlZoomPosition"
      />

      <!-- Single offer markers -->
      <l-feature-group
        v-if="getPickupIcon && getDeliveryIcon && hasLocation"
        ref="markers"
      >
        <l-marker
          ref="pickup"
          :lat-lng="offer.pickup.address.location"
          :icon="getPickupIcon"
        >
          <l-popup
            :content="formatAddress(offer.pickup.address, 'pickup')"
            :options="popupOptions"
          />
        </l-marker>
        <l-marker
          ref="delivery"
          :lat-lng="offer.delivery.address.location"
          :icon="getDeliveryIcon"
        >
          <l-popup
            :content="formatAddress(offer.delivery.address, 'delivery')"
            :options="popupOptions"
          />
        </l-marker>
      </l-feature-group>

      <l-polyline
        v-if="itinerary && !!itinerary.latlngs"
        :visible="itinerary.visible"
        :lat-lngs="itinerary.latlngs"
        :weight="itinerary.style.weight"
        :color="itinerary.style.color"
        :opacity="itinerary.style.opacity"
        :fill="false"
      />
    </l-map>

    <offer-travel
      v-if="offer && offer.travel && offer.travel.distance && offer.travel.duration"
      :distance="offer.travel.distance"
      :duration="offer.travel.duration"
      class="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-m-auto tw-mb-3"
    />
  </div>
</template>

<script>
  import { defineComponent, onMounted, toRefs, watch } from '@vue/composition-api'
  import {
    LMap,
    LMarker,
    LTileLayer,
    LFeatureGroup,
    LPolyline,
    LPopup,
    LControlZoom
  } from 'vue2-leaflet'

  import OfferTravel from './_subs/OfferTravel/index.vue'
  import OfferDetailMap from '@/components/OfferDetailMap/index.vue'

  import useMap from '@/composables/useMap'

  /**
   * @module component - OfferMapSection
   */
  export default defineComponent({
    name: 'OfferMapSection',
    components: {
      LMap,
      LMarker,
      LTileLayer,
      LPolyline,
      LFeatureGroup,
      LPopup,
      LControlZoom,
      OfferDetailMap,
      OfferTravel
    },
    props: {
      offer: {
        type: Object,
        required: true
      }
    },
    setup (props) {
      const { offer } = toRefs(props)
      const { map, markers, mapData, itinerary, retrieveItinerary, getPickupIcon, getDeliveryIcon, hasLocation, popupOptions, formatAddress } = useMap()

      onMounted(() => {
        retrieveItinerary(offer.value)
      })

      watch(offer, () => {
        retrieveItinerary(offer.value)
      })

      return {
        map,
        markers,
        formatAddress,
        popupOptions,
        mapData,
        itinerary,
        getPickupIcon,
        getDeliveryIcon,
        hasLocation
      }
    }
  })
</script>

<style lang="scss" scoped>

  .leaflet-map {
    height: 300px;
    z-index: 0;
  }

  .offer-travel {
    max-width: 280px;
  }

</style>
