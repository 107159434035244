<template>
  <address
    class="offer-direction-address tw-mb-0"
  >
    <div
      data-test="street-name"
    >
      {{ address.postal_code }} {{ address.city }}
    </div>
    <div
      class="tw-flex tw-items-center"
    >
      <ui-flag
        :country="address.country"
        inline
        data-test="flag"
        class="tw-align-middle"
      />
      <div
        v-text="$t(address.country)"
      />
    </div>
  </address>
</template>

<script>
  /**
   * @module component - OfferDirectionAddress
   * @param {object} address
   * @param {string} [address.street_name] - The street name, may be undefined
   * @param {string} address.city
   * @param {string} address.country
   * @param {string} address.postal_code
   */
  export default {
    name: 'OfferDirectionAddress',
    props: {
      address: {
        type: Object,
        required: true
      }
    }
  }
</script>
