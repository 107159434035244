<template>
  <!-- Single proposal from a carrier (ourselves) -->
  <span
    class="offer-proposal-wording"
  >
    <span
      v-if="proposalCount === 0"
      class="offer-proposal-wording"
      v-markdown="$tc(isOnDates ? 'offer_list.item.proposals.me_dates' : 'offer_list.item.proposals.me_amount', proposalCount, {
        count: $n(proposalCount),
        amount: isOnDates ? null : formattedAmount
      })"
    />

    <template
      v-else
    >
      <div
        :class="{
          'tw-text-blue-500': !hasSelfProposal
        }"
        class="tw-flex"
      >
        <img
          v-if="!hasSelfProposal"
          src="@/assets/img/icons/quotation/contract.svg"
          width="20"
          height="20"
          class="tw-mr-2"
          alt=""
          data-test="icon"
        >

        <!-- More than one proposal -->
        <span
          v-if="isOnDates"
        >
          {{ $tc(hasSelfProposal ? 'offer_list.item.proposals.me_and_many_proposals_dates' : 'offer_list.item.proposals.many_proposals_dates', proposalCount, {
            count: $n(proposalCount)
          }) }}
        </span>

        <!-- Proposals on the amounts -->
        <span
          v-else
          v-markdown="$tc(hasSelfProposal ? 'offer_list.item.proposals.me_and_many_proposals' : 'offer_list.item.proposals.many_proposals_amount', proposalCount, {
            count: $n(proposalCount),
            amount: isOnDates ? null : formattedAmount
          })"
        />
      </div>
    </template>
  </span>
</template>

<script>
  import { defineComponent, toRefs, computed } from '@vue/composition-api'

  import useI18n from '@/composables/useI18n'
  import useCurrency from '@/composables/useCurrency'

  /**
   * Component used to show the various versions of proposals texts, used in the
   * list and the offer detail. It handles all the logic
   * @module component - OfferProposalWording
   * @param {object} offer
   * @param {object} [proposal=null] - Optional proposal object
   */
  export default defineComponent({
    name: 'OfferProposalWording',
    props: {
      offer: {
        type: Object,
        required: true
      },
      proposal: {
        type: Object,
        default: null
      }
    },
    setup (props) {
      const { offer, proposal } = toRefs(props)
      const i18n = useI18n()

      const proposalData = computed(() => proposal.value || offer.value.proposal)
      const hasSelfProposal = computed(() => {
        const {
          proposal_count: proposalCount
        } = offer.value

        /**
         * TODO: Why do we have "state" and "status" for the same thing?
         * Determine why and remove the extra one.
         */
        return !!proposalData.value &&
          (proposalData.value.state === 'pending' || proposalData.value.status === 'pending') &&
          proposalCount >= 1
      })
      const proposalCount = computed(() => {
        return hasSelfProposal.value
          ? offer.value.proposal_count - 1
          : offer.value.proposal_count
      })

      const price = computed(() => {
        const { pricing } = offer.value
        return pricing.carrier_price || pricing.price
      })

      const isOnDates = computed(() => typeof offer.value.lowest_proposal_price === 'undefined')
      const formattedAmount = computed(() => useCurrency(offer.value.lowest_proposal_price, offer.value.pricing.currency, i18n.value.locale))

      return {
        formattedAmount,
        hasSelfProposal,
        price,
        isOnDates,
        proposalCount,
        proposalData
      }
    }
  })
</script>

<style lang="scss">

  .offer-proposal-wording {
    &.markdown p,
    .markdown p {
      display: inline-block;
      margin: 0;
    }

    strong {
      font-weight: 500;
    }
  }

</style>
