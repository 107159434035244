<template>
  <div class="offer-detail-map">
    <l-map
      ref="map"
      :zoom="mapData.zoom"
      :max-zoom="mapData.maxZoom"
      :min-zoom="mapData.minZoom"
      :options="customOptions"
      :center="mapData.center"
      class="leaflet-map"
    >
      <l-tile-layer
        :url="mapData.url"
        :attribution="mapData.attribution"
      />

      <l-control-zoom
        v-if="hasZoomControls"
        :position="mapData.controlZoomPosition"
      />

      <!-- Single offer markers -->
      <l-feature-group
        v-if="hasCurrentOfferUuid && getPickupIcon && hasLocation"
        ref="markers"
      >
        <l-marker
          ref="pickup"
          :lat-lng="getCurrentOffer.pickup.address.location"
          :icon="getPickupIcon"
        >
          <l-popup
            :content="formatAddress(getCurrentOffer.pickup.address, 'pickup')"
            :options="popupOptions"
          />
        </l-marker>
        <l-marker
          ref="delivery"
          :lat-lng="getCurrentOffer.delivery.address.location"
          :icon="getDeliveryIcon"
        >
          <l-popup
            :content="formatAddress(getCurrentOffer.delivery.address, 'delivery')"
            :options="popupOptions"
          />
        </l-marker>
      </l-feature-group>

      <l-polyline
        v-if="itinerary && !!itinerary.latlngs"
        :visible="itinerary.visible"
        :lat-lngs="itinerary.latlngs"
        :weight="itinerary.style.weight"
        :color="itinerary.style.color"
        :opacity="itinerary.style.opacity"
        :fill="false"
      />
    </l-map>
  </div>
</template>

<script>
  import {
    LMap,
    LMarker,
    LTileLayer,
    LFeatureGroup,
    LPolyline,
    LPopup,
    LControlZoom
  } from 'vue2-leaflet'
  import { computed, defineComponent, onMounted, toRefs, watch, nextTick } from '@vue/composition-api'

  import useMap from '@/composables/useMap'
  import useStore from '@/composables/useStore'

  export default defineComponent({
    name: 'OfferDetailMap',
    components: {
      LMap,
      LMarker,
      LTileLayer,
      LControlZoom,
      LPolyline,
      LFeatureGroup,
      LPopup
    },
    props: {
      hasZoomControls: {
        type: Boolean,
        default: false
      },
      hasScrollWheelZoom: {
        type: Boolean,
        default: false
      }
    },
    setup (props) {
      const { hasScrollWheelZoom } = toRefs(props)
      const { map, markers, mapData, itinerary, retrieveItinerary, getPickupIcon, getDeliveryIcon, hasLocation, popupOptions, formatAddress, zoomToMarkers } = useMap()
      const store = useStore()

      const hasCurrentOfferUuid = computed(() => store.value.getters['offers/hasCurrentOfferUuid'])
      const getCurrentOffer = computed(() => store.value.getters['offers/getCurrentOffer'])

      onMounted(async () => {
        if (getCurrentOffer.value) {
          retrieveItinerary(getCurrentOffer.value)
          await nextTick()
          zoomToMarkers()
        }
      })

      watch(getCurrentOffer, async (newVal) => {
        if (newVal) {
          retrieveItinerary(getCurrentOffer.value)
          await nextTick()
          zoomToMarkers()
        }
      })

      const customOptions = computed(() => ({
        ...mapData.options,
        scrollWheelZoom: hasScrollWheelZoom.value
      }))

      return {
        map,
        markers,
        formatAddress,
        popupOptions,
        mapData,
        itinerary,
        getPickupIcon,
        getDeliveryIcon,
        hasLocation,
        customOptions,
        hasCurrentOfferUuid,
        getCurrentOffer
      }
    }
  })
</script>

<style lang="scss" scoped>

  .offer-detail-map {
    height: 250px;

    .vue2leaflet-map {
      z-index: 0;
    }
  }

</style>
