<template>
  <div class="offer-price-section tw-bg-white tw-rounded">
    <offer-pricing-section
      :offer="offer"
    />
    <offer-proposal-section
      :offer="offer"
      :proposal="proposal"
    />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import OfferPricingSection from './_subs/OfferPricingSection/index.vue'
  import OfferProposalSection from './_subs/OfferProposalSection/index.vue'

  /**
   * @module component - OfferPriceSection
   */
  export default defineComponent({
    name: 'OfferPriceSection',
    components: {
      OfferPricingSection,
      OfferProposalSection
    },
    props: {
      offer: {
        type: Object,
        required: true
      },
      proposal: {
        type: Object,
        default: null
      }
    }
  })
</script>

<style lang="scss" scoped>
.offer-price-section .offer-pricing-section, .offer-price-section .offer-proposal-section {
  position: relative;
}
.offer-price-section .offer-pricing-section::after, .offer-price-section .offer-proposal-section::after {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 233, 233, var(--tw-bg-opacity));
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  width: calc(100% - 5rem);
  height: 1px;
}
</style>
