<template>
  <div class="offer-proposal-card tw-bg-white tw-rounded tw-relative">
    <div class="offer-proposal-card__container tw-mb-4">
      <div
        class="offer-proposal-card__container__header tw-flex tw-items-center tw-justify-between tw-bg-gray-300 tw-border-gray-500 tw-rounded-tl tw-rounded-tr tw-border tw-border-solid tw-border-b-0 tw-px-6"
        data-test="header"
      >
        <h4
          class="tw-text-base tw-font-medium tw-mb-0"
          data-test="title"
          v-text="$options.filters.capitalize($t('your_proposal'))"
        />

        <UiBadge
          :color="status === 'danger'
            ? 'red'
            : status === 'accepted'
              ? 'teal'
              : 'cyan'"
          size="sm"
          data-test="proposal-state"
        >
          {{ $options.filters.capitalize($tc('proposal_state.' + proposal.status, 1)) }}
        </UiBadge>
      </div>

      <div class="tw-flex tw-flex-col tw-rounded-br tw-rounded-bl tw-border-gray-500 tw-border-solid tw-border tw-border-b-t tw-p-6">
        <div
          class="tw-flex tw-flex-col lg:tw-flex-row"
        >
          <offer-group-item
            :title="$options.filters.capitalize($t('app.labels.price'))"
            :active="!!proposal.price"
            class="tw-mb-6 lg:tw-mb-0 lg:tw-mr-6 tw-flex-1"
            data-test="price"
          >
            {{ price }}
          </offer-group-item>

          <offer-group-item
            :title="$t('pickup')"
            :active="!!proposal.pickup_date"
            class="tw-mb-6 lg:tw-mb-0 lg:tw-mr-6 tw-flex-1"
            data-test="pickup"
          >
            {{ pickupDate }}
          </offer-group-item>
          <offer-group-item
            :title="$t('delivery')"
            :active="!!proposal.delivery_date"
            class="tw-mb-6 lg:tw-mb-0 lg:tw-mr-6 tw-flex-1"
            data-test="delivery"
          >
            {{ deliveryDate }}
          </offer-group-item>

          <offer-group-item
            :title="$options.filters.capitalize($t('app.labels.expires_at'))"
            class="tw-flex-1"
            data-test="expiration"
          >
            {{ expiresAt }}
          </offer-group-item>
        </div>

        <offer-group-item
          v-if="proposal.comment"
          :title="$options.filters.capitalize($t('app.labels.comment'))"
          :active="true"
          class="tw-border tw-border-l-0 tw-border-r-0 tw-border-b-0 tw-border-gray-500 tw-border-solid tw-pt-3 tw-mt-4"
          data-test="comment"
        >
          {{ proposal.comment }}
        </offer-group-item>
      </div>
    </div>

    <div
      v-if="isAvailableForBooking"
      class="tw-flex tw-justify-end"
    >
      <template
        v-if="proposal.status === 'pending'"
      >
        <ui-button
          :title="$t('cancel_proposal')"
          variant="danger"
          outline
          class="tw-mr-4 cancel-proposal"
          data-test="cancel-proposal"
          @click="cancelProposal"
        >
          {{ $t('cancel') | capitalize }}
        </ui-button>

        <ui-button
          :title="$t('edit_proposal')"
          variant="info"
          class="edit-proposal"
          data-test="edit-proposal"
          @click="createProposal(false)"
        >
          {{ $t('edit') | capitalize }}
        </ui-button>
      </template>
      <template
        v-else
      >
        <ui-button
          :title="$t('new_proposal')"
          variant="info"
          class="renew-proposal"
          data-test="renew-proposal"
          @click="createProposal(true)"
        >
          {{ $t('new_proposal') }}
        </ui-button>
      </template>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import { defineComponent, toRefs, computed } from '@vue/composition-api'
  import { i18n } from '@/locales'

  import OfferGroupItem from '@/views/Carriers/Offers/components/OfferDetail/components/OfferGroupItem/index.vue'
  import useCurrency from '@/composables/useCurrency'
  import useMatomo from '@/composables/useMatomo'
  import { EventBus } from '@/services/EventBus'

  /**
   * @module component - OfferProposalCard
   * @param {object} proposal
   * @param {object} offer
   * @param {string} currency
   */
  export default defineComponent({
    name: 'OfferProposalCard',
    components: {
      OfferGroupItem
    },
    props: {
      proposal: {
        type: Object,
        required: true
      },
      offer: {
        type: Object,
        required: true
      },
      currency: {
        type: String,
        required: true
      }
    },
    setup (props, { emit }) {
      const { offer, proposal, currency } = toRefs(props)
      const matomo = useMatomo()

      const pickupDate = computed(() => proposal.value.pickup_date
        ? moment(proposal.value.pickup_date).format('ll')
        : moment(offer.value.pickup.time_slot.date).format('ll'))

      const deliveryDate = computed(() => proposal.value.delivery_date
        ? moment(proposal.value.delivery_date).format('ll')
        : moment(offer.value.delivery.time_slot.date).format('ll'))

      const status = computed(() => {
        let { status } = proposal.value

        const danger = ['expired', 'cancelled', 'revoked', 'refused']
        if (danger.includes(status)) {
          status = 'danger'
        }

        return status
      })
      const expiresAt = computed(() => `${moment(proposal.value.expires_at).format('ll')} - ${moment(proposal.value.expires_at).format('LT')}`)

      const price = computed(() => {
        return proposal.value.price
          ? useCurrency(proposal.value.price, currency.value, i18n.locale)
          : useCurrency(offer.value.pricing.price || offer.value.pricing.carrier_price, currency.value, i18n.locale)
      })

      const isAvailableForBooking = computed(() => {
        if (!offer.value) return false
        if (typeof offer.value.available_for_booking === 'undefined') return true
        if (offer.value.available_for_booking) return true

        return false
      })

      function cancelProposal () {
        if (matomo) {
          matomo.trackEvent('Offers', 'Initiated Proposal Cancellation', offer.value.uuid)
        }

        EventBus.$emit('cancel-proposal-modal', proposal.value)
      }

      function createProposal (renew = false) {
        if (matomo) {
          matomo.trackEvent('Offers', renew
            ? 'Initiated Proposal Reactivation'
            : 'Initiated Proposal Change', offer.value.uuid)
        }

        emit('toggle-proposal-modal', {
          renew
        })
      }

      return {
        status,
        pickupDate,
        deliveryDate,
        expiresAt,
        price,
        isAvailableForBooking,
        cancelProposal,
        createProposal
      }
    }
  })
</script>

<style lang="scss" scoped>
.offer-proposal-card__container__header {
  min-height: 2.5rem;
}
.offer-proposal-card__container .offer-group-item:not(:last-child) {
  position: relative;
}
@media (min-width: 1024px) {
  .offer-proposal-card__container .offer-group-item:not(:last-child)::after {
    height: 100% !important;
    width: 1px !important;
    left: auto !important;
    bottom: auto !important;
    right: 0;
  }
}
.offer-proposal-card__container .offer-group-item:not(:last-child)::after {
  --tw-bg-opacity: 1;
  background-color: rgba(216, 216, 216, var(--tw-bg-opacity));
  position: absolute;
  left: 0px;
  width: 100%;
  content: '';
  height: 1px;
  bottom: -0.75rem;
}
</style>
