var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"offer-proposal-wording"},[(_vm.proposalCount === 0)?_c('span',{directives:[{name:"markdown",rawName:"v-markdown",value:(_vm.$tc(_vm.isOnDates ? 'offer_list.item.proposals.me_dates' : 'offer_list.item.proposals.me_amount', _vm.proposalCount, {
      count: _vm.$n(_vm.proposalCount),
      amount: _vm.isOnDates ? null : _vm.formattedAmount
    })),expression:"$tc(isOnDates ? 'offer_list.item.proposals.me_dates' : 'offer_list.item.proposals.me_amount', proposalCount, {\n      count: $n(proposalCount),\n      amount: isOnDates ? null : formattedAmount\n    })"}],staticClass:"offer-proposal-wording"}):[_c('div',{staticClass:"tw-flex",class:{
        'tw-text-blue-500': !_vm.hasSelfProposal
      }},[(!_vm.hasSelfProposal)?_c('img',{staticClass:"tw-mr-2",attrs:{"src":require("@/assets/img/icons/quotation/contract.svg"),"width":"20","height":"20","alt":""}}):_vm._e(),(_vm.isOnDates)?_c('span',[_vm._v(" "+_vm._s(_vm.$tc(_vm.hasSelfProposal ? 'offer_list.item.proposals.me_and_many_proposals_dates' : 'offer_list.item.proposals.many_proposals_dates', _vm.proposalCount, { count: _vm.$n(_vm.proposalCount) }))+" ")]):_c('span',{directives:[{name:"markdown",rawName:"v-markdown",value:(_vm.$tc(_vm.hasSelfProposal ? 'offer_list.item.proposals.me_and_many_proposals' : 'offer_list.item.proposals.many_proposals_amount', _vm.proposalCount, {
          count: _vm.$n(_vm.proposalCount),
          amount: _vm.isOnDates ? null : _vm.formattedAmount
        })),expression:"$tc(hasSelfProposal ? 'offer_list.item.proposals.me_and_many_proposals' : 'offer_list.item.proposals.many_proposals_amount', proposalCount, {\n          count: $n(proposalCount),\n          amount: isOnDates ? null : formattedAmount\n        })"}]})])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }