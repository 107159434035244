<template>
  <offer-section
    class="offer-proposal-section"
  >
    <template
      #title
    >
      <offer-section-title
        :title="$t('offer.proposals.title')"
      >
        <ui-ctk-icon
          name="bid-2"
          class="tw-text-blue-500 tw-text-3xl"
        />
      </offer-section-title>

      <template
        v-if="isAvailableForBooking"
      >
        <div
          v-if="!offer.proposal && (!proposal || (proposal && !proposal.uuid))"
          class="tw-flex tw-flex-col tw-flex-shrink-0 xl:tw-ml-4"
        >
          <ui-button
            :rounded="false"
            class="tw-text-sm lg:tw-self-end tw-flex-shrink-0"
            variant="info"
            outline
            data-test="new-proposal"
            @click="toggleProposalModal({ toggleValue: true })"
          >
            {{ $t('offer.proposals.new_proposal') }}
          </ui-button>
        </div>
      </template>
    </template>

    <div class="offer-detail-proposal__content tw-flex tw-flex-col">
      <p
        v-if="!hasProposalCard"
        v-text="$t('offers.paragraphs.proposal.new')"
        data-test="new-wording"
      />

      <offer-proposal-wording
        v-if="offer.proposal_count > 0"
        class="tw-mb-4"
        :offer="offer"
        :proposal="offer.proposal || proposal"
        data-test="wording"
      />

      <offer-proposal-card
        v-if="hasProposalCard"
        :offer="offer"
        :proposal="offer.proposal || proposal"
        :currency="offer.pricing.currency"
        data-test="card"
        @toggle-proposal-modal="toggleProposalModal"
      />
    </div>
  </offer-section>
</template>

<script>
  import { defineComponent, toRefs, computed } from '@vue/composition-api'

  import OfferSection from '@/views/Carriers/Offers/components/OfferDetail/components/OfferSection/index.vue'
  import OfferSectionTitle from '@/views/Carriers/Offers/components/OfferDetail/components/OfferSection/OfferSectionTitle/index.vue'
  import OfferProposalCard from './_subs/OfferProposalCard/index.vue'
  import OfferProposalWording from './_subs/OfferProposalWording/index.vue'
  import useMatomo from '@/composables/useMatomo'
  import { EventBus } from '@/services/EventBus'

  /**
   * @module component - OfferProposalSection
   */
  export default defineComponent({
    name: 'OfferProposalSection',
    components: {
      OfferSection,
      OfferSectionTitle,
      OfferProposalCard,
      OfferProposalWording
    },
    props: {
      offer: {
        type: Object,
        required: true
      },
      proposal: {
        type: Object,
        default: null
      }
    },
    setup (props) {
      const { offer, proposal } = toRefs(props)
      const matomo = useMatomo()

      const hasProposalCard = computed(() => {
        return offer.value.proposal || (proposal.value && proposal.value.uuid)
      })

      const isAvailableForBooking = computed(() => {
        if (typeof offer.value.available_for_booking === 'undefined') return true
        if (offer.value.available_for_booking) return true

        return false
      })

      // @ts-ignore
      function toggleProposalModal ({ renew, toggleValue }) {
        if (toggleValue && matomo) {
          matomo.trackEvent('Offers', 'Initiated Proposal', offer.value.uuid)
        }

        EventBus.$emit('toggle-proposal-modal', renew)
      }

      return {
        isAvailableForBooking,
        hasProposalCard,
        toggleProposalModal
      }
    }
  })
</script>
