<template>
  <offer-group-item
    v-if="hasTailLift || hasHandling"
    class="offer-options"
    :title="$t('offers.labels.options')"
  >
    <offer-specificity-group
      data-test="group"
    >
      <offer-specificity-item
        v-if="hasTailLift"
        :icon="'hatch'"
        :title="$t('app.labels.vehicle_options')"
        :content="$t('app.values.vehicle_tail_lift')"
        :variant="direction === 'pickup' ? 'info' : 'primary'"
        data-test="tail-lift"
      />
      <offer-specificity-item
        v-if="hasHandling"
        :icon="'logistic'"
        :title="$t('app.labels.handling')"
        :content="$t('handling_by_the_driver')"
        :variant="direction === 'pickup' ? 'info' : 'primary'"
        data-test="handling"
      />
    </offer-specificity-group>
  </offer-group-item>
</template>

<script>
  import { defineComponent, computed, toRefs } from '@vue/composition-api'

  import OfferGroupItem from '@/views/Carriers/Offers/components/OfferDetail/components/OfferGroupItem/index.vue'
  import OfferSpecificityGroup from '@/views/Carriers/Offers/components/OfferDetail/components/OfferSpecificityGroup/index.vue'
  import OfferSpecificityItem from '@/views/Carriers/Offers/components/OfferDetail/components/OfferSpecificityGroup/_subs/OfferSpecificityItem/index.vue'

  export default defineComponent({
    name: 'OfferOptions',
    components: {
      OfferGroupItem,
      OfferSpecificityGroup,
      OfferSpecificityItem
    },
    props: {
      offer: {
        type: Object,
        required: true
      },
      direction: {
        type: String,
        required: true
      }
    },
    setup (props) {
      const { offer, direction } = toRefs(props)
      const hasHandling = computed(() => offer.value[direction.value].handling.driver)
      const hasTailLift = computed(() => offer.value[direction.value].handling.tail_lift)

      return {
        hasHandling,
        hasTailLift
      }
    }
  })
</script>

<style lang="scss" scoped>
.offer-options .offer-specificity-group {
  margin-bottom: 0px;
}
</style>
