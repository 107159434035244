<template>
  <div
    class="offer-travel-item tw-flex tw-items-center tw-justify-center"
  >
    <ui-ctk-icon
      :name="icon"
      class="tw-mr-2"
      data-test="icon"
    />
    <span
      v-text="label"
      data-test="label"
    />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - OfferTravelItem
   * @param {string} icon
   * @param {string} label
   */
  export default defineComponent({
    name: 'OfferTravelItem',
    props: {
      icon: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      }
    }
  })
</script>
