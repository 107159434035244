<template>
  <div class="offer-directions-section tw-flex tw-flex-col md:tw-flex-row">
    <offer-direction-inline-section
      :offer="offer"
      class="tw-hidden md:tw-flex"
    />

    <div class="md:tw-hidden">
      <offer-direction-section
        :offer="offer"
        :direction="'pickup'"
      />
      <offer-direction-section
        :offer="offer"
        :direction="'delivery'"
      />
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import OfferDirectionSection from './_subs/OfferDirectionSection/index.vue'
  import OfferDirectionInlineSection from './_subs/OfferDirectionInlineSection/index.vue'

  export default defineComponent({
    components: {
      OfferDirectionSection,
      OfferDirectionInlineSection
    },
    props: {
      offer: {
        type: Object,
        required: true
      }
    }
  })
</script>

<style lang="scss">

  .offer-directions-section .offer-direction-section:nth-child(1) {
    padding-bottom: 0;
  }

</style>
