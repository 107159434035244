<template>
  <div class="offer-section-title tw-flex tw-items-center tw-relative">
    <div class="offer-section-title__icon tw-flex tw-flex-col tw-justify-center tw-items-center tw-absolute">
      <slot />
    </div>
    <h2
      v-text="title"
      class="tw-mb-0 tw-font-medium tw-text-xl"
      data-test="title"
    />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - OfferSectionTitle
   * @param {string} title
   */
  export default defineComponent({
    name: 'OfferSectionTitle',
    props: {
      title: {
        type: String,
        required: true
      }
    }
  })
</script>

<style lang="scss" scoped>
.offer-section-title {
  min-height: 45px;
}
.offer-section-title__icon {
  height: 2rem;
  margin: auto;
  top: 0px;
  bottom: 0px;
  width: 2rem;
  left: -32px;
}
</style>
