<template>
  <div
    v-b-tooltip.hover
    :title="$t('explication_duration_distance')"
    class="offer-travel tw-flex tw-items-center tw-rounded tw-h-10 tw-bg-white tw-text-blue-500"
  >
    <offer-travel-item
      :icon="'time'"
      :label="$options.filters.duration(duration)"
      class="tw-w-1/2"
      data-test="duration"
    />
    <offer-travel-item
      :icon="'started'"
      :label="$t('app.values.distance', {
        distance: $n(formattedDistance)
      })"
      class="tw-w-1/2"
      data-test="distance"
    />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import OfferTravelItem from './_subs/OfferTravelItem/index.vue'

  /**
   * @module component - OfferTravel
   * @param {number} distance - Value in meters
   * @param {number} duration - Value in seconds
   */
  export default defineComponent({
    name: 'OfferTravel',
    components: {
      OfferTravelItem
    },
    props: {
      distance: {
        type: Number,
        required: true
      },
      duration: {
        type: Number,
        required: true
      }
    },
    computed: {
      /**
       * @function formattedDistance
       * @returns {string}
       */
      formattedDistance () {
        return (this.distance / 1000).toFixed(0)
      }
    }
  })
</script>

<style lang="scss" scoped>
.offer-travel .offer-travel-item:nth-child(1) {
  position: relative;
}
.offer-travel .offer-travel-item:nth-child(1)::after {
  --tw-bg-opacity: 1;
  background-color: rgba(216, 216, 216, var(--tw-bg-opacity));
  margin: auto;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  content: '';
  width: 1px;
  height: 20px;
}
</style>
