<template>
  <div
    class="offer-detail tw-bg-white tw-relative tw-z-10"
  >
    <div class="tw-flex tw-flex-col">
      <offer-header
        :offer="offer"
        :proposal="proposal"
        data-test="header"
      />
      <offer-map-section
        v-if="hasMap"
        :offer="offer"
        data-test="map"
      />
      <offer-price-section
        :offer="offer"
        :proposal="proposal"
        data-test="price"
      />
      <offer-directions-section
        :offer="offer"
        data-test="directions"
      />
      <offer-load-section
        :offer="offer"
        class="tw-mb-12 md:tw-mb-0"
        data-test="load"
      />

      <offer-booking-footer
        :offer="offer"
        class="tw-w-full"
        data-test="footer"
      />
    </div>
  </div>
</template>

<script>
  import { defineComponent, onMounted, toRefs, watch } from '@vue/composition-api'

  import OfferHeader from './components/OfferHeader/index.vue'
  import OfferLoadSection from './components/OfferLoadSection/index.vue'
  import OfferDirectionsSection from './components/OfferDirectionsSection/index.vue'
  import OfferMapSection from './components/OfferMapSection/index.vue'
  import OfferPriceSection from './components/OfferPriceSection/index.vue'
  import OfferBookingFooter from './components/OfferBookingFooter/index.vue'

  import useSticky from '@/composables/useSticky'

  /**
   * Component regrouping the whole offer detail. This does not include the error states.
   * @module component - OfferDetail
   */
  export default defineComponent({
    name: 'OfferDetail',
    components: {
      OfferHeader,
      OfferLoadSection,
      OfferMapSection,
      OfferPriceSection,
      OfferDirectionsSection,
      OfferBookingFooter
    },
    props: {
      offer: {
        type: Object,
        required: true
      },
      proposal: {
        type: Object,
        default: null
      },
      hasMap: {
        type: Boolean,
        default: true
      }
    },
    setup (props) {
      const { offer } = toRefs(props)
      const { create: updateBookingFooter } = useSticky('.offer-booking-footer', {
        verticalPosition: 'bottom'
      })

      watch(offer, (currentOffer, prevOffer) => {
        /**
         * Quick thingy to whenever the offer changes, scroll to the top of the
         * offer detail, but only relies on the uuid chnage.
         */
        if (prevOffer && prevOffer.uuid !== currentOffer.uuid) {
          const detail = document.querySelector('.offer-detail')
          if (!detail) return

          detail.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          })
        }
      }, { immediate: true })

      onMounted(() => {
        updateBookingFooter()
      })
    }
  })
</script>

<style lang="scss" scoped>
@media only screen and (max-width: $breakpoint-tablet) {
  .offer-detail .offer-booking-footer {
    position: fixed !important;
  }
}
.offer-detail .offer-directions-section {
  position: relative;
}
.offer-detail .offer-directions-section::after {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 233, 233, var(--tw-bg-opacity));
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  width: calc(100% - 5rem);
  height: 1px;
}
</style>
